
import React from 'react'
import Marketing from '../../templates/marketing'

const MarketingEsPage = () => {
    return (
        <Marketing lang="en">
        </Marketing>
    )
}

export default MarketingEsPage
